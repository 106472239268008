body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ######## */

.main {
  padding: 0px 2%;
  margin-bottom: 25px;
}

/* ####### */
.allContent {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  height: 100%;
  margin-top: 25px;
}


/* ######## */

.myInfo {
  display: flex;
  flex-direction: row;
  flex: 3;
  gap: 1rem;
}

.aboutMe {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #757575
}

.myImg {
  position: relative;
}

.myImg img {
  width: 100%;
  height: 250px;
}

.fullName {
  position: absolute;
  left: 20px;
  bottom: 0px;
  color: black;
}

.fullName h2 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
}



.contactSkill i {
  display: inline-block;
  width: 20px;
  height: 20px;
}

hr {
  border: 0;
  border-top: 1px solid rgb(254, 184, 0);
  margin: 20px 10px;
}

.mesureBox {
  width: 90%;
  height: 15px;
  margin: 0 auto;
  background: rgb(238, 238, 238);
  border-radius: 15px;
  margin-bottom: 25px;
}

.skill {
  margin-left: 25px;
}

b {
  font-weight: bolder;
}

.html {
  width: 90%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}

.css {
  width: 75%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}

.bootstrap {
  width: 90%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}

.javascript {
  width: 70%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}


.node {
  width: 70%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}

.mongo {
  width: 80%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}

.ejs {
  width: 80%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}

.react {
  width: 60%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}

.french {
  width: 100%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}

.english {
  width: 50%;
  height: 100%;
  background: rgb(254, 184, 0);
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  color: rgb(68, 68, 68);
}

.rightSection {
  flex: 9;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #757575
}

.rightSection_up {
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;


}

.rightSection_down {
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;


}


@media (320px<=width <=480px) {
  .allContent {
    flex-direction: column;
  }


}